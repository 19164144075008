import styled from "styled-components";

export default styled.div.attrs({ className: "password-textbox" })`
  width: 100%;

  .MuiInputBase-root {
    border: 2px solid #d1dde5;
    border-radius: 45px !important;
    opacity: 1;
    background-color: #ffffff;

    & input,
    textarea {
      border: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }

    .start-adornment {
      width: 42px;
    }

    .MuiInputAdornment-root {
      margin: 5px 10px 5px 0px;
    }
  }

  .Mui-error {
    border-color: #f76155;
    opacity: 1;
  }
`;
