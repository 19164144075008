import React, { useState, useRef } from "react";
import { Button, CssBaseline, Container } from "@material-ui/core";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { clone } from "underscore";

import PasswordTextbox from "../../components/PasswordTextbox/PasswordTextbox";
import { ResetPasswordApi } from "../../common/module_wise_api_url/onboarding";
import { commonApiFN } from "../../common/api";
import {
  toastify,
  validateString,
  validatePassword,
} from "../../helper/utils/validation";
import { INTERSTRIDE_LOGO_WHITE } from "../../helper/utils/constants";

import BackArrow from "../../assets/svg/back-arrow.svg";

const ResetPassword = (props) => {
  const formRef = useRef();
  const [newPassword, setNewPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);

  const searchParmas = window.location.search;
  const params = new URLSearchParams(searchParmas);
  const token = params.get("token");
  var isForgotPassword = 0;

  if (params.get("forgot_password")) {
    isForgotPassword = 1;
  }

  const handleResetPassword = async (values) => {
    let requestData = {};

    requestData = clone(ResetPasswordApi);
    requestData.data = {
      employer_profile: {
        password: newPassword,
        confirm_password: newPassword,
        token: token,
        forgot_password: isForgotPassword,
      },
    };

    const response = await commonApiFN(requestData);

    if (response.success) {
      toastify("success", response.message);
      props.history.push("/");
    } else {
      toastify("error", response.message);
    }
  };

  return (
    <section
      className="OnboardingWrapper EmployersWrapper"
      style={{ minHeight: "100vh" }}
    >
      <CssBaseline />
      <div className="BrandLogo text-center mb-30">
        <a href="/">
          <img src={INTERSTRIDE_LOGO_WHITE} style={{ height: 36 }} alt="Logo" />
        </a>
      </div>

      <div className="back-url">
        <Link to="/" className="link-focus">
          <img
            src={BackArrow}
            id="BackArrow"
            className="back-link"
            width="18px"
            alt="Back arrow"
          />
        </Link>
      </div>
      <h1 className="text-center mb-30">Reset your password?</h1>
      <Container component="main" maxWidth="xs">
        <Formik
          innerRef={formRef}
          initialValues={{
            newPassword: "",
            repeatPassword: "",
          }}
          validate={() => {
            const errors = {};

            if (validateString(newPassword)) {
              errors.newPassword = "Please enter new password";
            } else if (newPassword.trim().length < 8) {
              errors.newPassword = "Password should have at least 8 characters";
            } else if (validatePassword(newPassword)) {
              errors.newPassword =
                "Password should have both uppercase and lowercase characters";
            } else if (validateString(repeatPassword)) {
              errors.repeatPassword = "Please enter confirm password";
            } else if (newPassword.trim() !== repeatPassword.trim()) {
              errors.repeatPassword =
                "New password and confirm password don't match";
            }

            return errors;
          }}
          onSubmit={() => {
            handleResetPassword();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="form resetpassword"
              name="form"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <PasswordTextbox
                  variant="outlined"
                  fullWidth
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  label="New password"
                  autoComplete="newPassword"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onBlur={handleBlur}
                  error={errors.newPassword && touched.newPassword}
                  errorText={errors.newPassword}
                />
              </div>
              <div className="form-group">
                <PasswordTextbox
                  variant="outlined"
                  fullWidth
                  id="repeatPassword"
                  name="repeatPassword"
                  label="Confirm password"
                  autoComplete="repeatPassword"
                  placeholder="Confirm password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  onBlur={handleBlur}
                  error={errors.repeatPassword && touched.repeatPassword}
                  errorText={errors.repeatPassword}
                />
              </div>

              <div className="">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="btn btn-negative"
                  style={{ textTransform: "none" }}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Container>
      <div
        className="text-center"
        style={{ color: "#fff", marginTop: "-70px" }}
      >
        If you don’t see the email, please check your spam folder. Still having
        problems? Email us at &nbsp;
        <a
          href={`mailto:contact@interstride.com`}
          className="link-focus"
          style={{ color: "#E5190B", borderColor: "#E5190B" }}
        >
          contact@interstride.com
        </a>
      </div>
    </section>
  );
};

export default ResetPassword;
